import {
  Button,
  Dialog,
  DialogBody,
  DialogFooter,
  DialogHeader,
  Input,
  Typography,
} from "@material-tailwind/react";
import axios from "../api/axios";
import { useState, useEffect } from "react";
import { useStore } from "../store/Zustand";
import { useAuth } from "../store/auth";
import { ORDER_CHOPPING } from "../Constants";
import { useNavigate } from "react-router";

export default function OrderChopping({ file_name, carefill_id }) {
  const token = useAuth((state) => state.token);
  const resetAuth = useAuth((state) => state.resetAuth);

  const navigate = useNavigate();


  const orderChoppingModal = useStore((state) => state.orderChoppingModal);
  const setOrderChoppingModal = useStore(
    (state) => state.setOrderChoppingModal
  );
  const setnewMappingList = useStore((state) => state.setnewMappingList);
  const [from_date, setFromDate] = useState("");
  const [to_date, setToDate] = useState("");
  const [date, setDate] = useState("");

  const handleSubmit = async (e) => {
    const reqData = {
      file_name: file_name,
      carefill_id: carefill_id,
      from_date: date,
    };
    // console.log(reqData)
    setOrderChoppingModal({ status: false });
    try {
      const response = await axios.put(ORDER_CHOPPING, reqData,
        {
          method: "put",
          headers: {
            "Content-Type": "application/json",
            "x-access-tokens": token,
          },
        })
      // console.log(file_name)
      if (response.status === 201) {
        // console.log(response)
        alert(response.data.status_message)
        // console.log(response)
        setFromDate(response.data.from_date);
        setToDate(response.data.to_date);
        setnewMappingList(true);
        setDate("");
        // console.log(response.data)
      } else {
        alert(response.data.status_message)
      }
    } catch (err) {
      // setDate("")
      if (err?.response?.data?.status_message) {
        alert(err?.response?.data?.status_message);
      } else if (err.response.status === 500) {
        alert(err.response.data.status_message);
      } else if (err.response.status === 400) {
        alert(err.response.data.status_message);
      } else if (err.response.status === 403) {
        alert(err.response.data.status_message)
        resetAuth()
        navigate("/generateOTP")
      } else {
        alert(err.response.data.status_message);
      }
    }
  };
  // const handleSubmit = () => {
  //   alert("ok");
  //   // console.log(orderChoppingModal.data.from_date)
  //   setOrderChoppingModal({ status: false });
  // }

  useEffect(() => {
    if (orderChoppingModal.status === true) {
      setFromDate(orderChoppingModal.data.from_date)
      setToDate(orderChoppingModal.data.to_date)
    }
    else {
      setFromDate("");
      setToDate("");

    }
  }, [orderChoppingModal]);

  return (
    <>
      <Dialog open={orderChoppingModal.status} handler={setOrderChoppingModal}>
        <DialogHeader>
          <span className="w-full text-center">Order Chopping</span>
        </DialogHeader>
        <DialogBody
          divider
          className="max-h-[25rem] overflow-scroll py-4 sm:h-[28rem] md:h-[20rem]"
        >
          <div className="w-full text-center">
            <form className="mt-2 mb-2 px-10">
              <div className=" flex flex-col gap-9">
                <Input
                  size="lg"
                  label="From Date"
                  type="text"
                  color="blue"
                  // pattern="\d{4}-\d{2}-\d{2}"
                  name="from_date"
                  defaultValue={from_date}
                  // min={from_date}
                  readOnly
                />
                <Input
                  size="lg"
                  label="To Date"
                  type="text"
                  color="blue"
                  // pattern="\d{4}-\d{2}-\d{2}"
                  name="to_date"
                  defaultValue={to_date}
                  // max={to_date}
                  readOnly
                />
                {
                  <Typography variant="h6" color="black">
                    Order Splitting Date <br />
                    {from_date} - {to_date}
                  </Typography>
                }
                <Input
                  size="lg"
                  label="Date"
                  type="date"
                  color="blue"
                  // pattern="\d{4}-\d{2}-\d{2}"
                  name="date"
                  onChange={(e) => {
                    setDate(e.target.value);
                  }}
                  value={date}
                />
              </div>
            </form>
          </div>
        </DialogBody>

        <DialogFooter>
          <div className="flex flex-row content-end">
            <Button
              variant="text"
              color="blue"
              className="mr-3 bg-green-600 text-white hover:bg-gray-600"
              onClick={() => {
                handleSubmit();
              }}
            >
              <span>Go</span>
            </Button>
            <Button
              variant="text"
              color="red"
              className="mr-9 bg-red-600 text-white hover:bg-gray-600"
              onClick={() => {
                setOrderChoppingModal(false);
              }}
            >
              <span>Cancel</span>
            </Button>
          </div>
        </DialogFooter>
      </Dialog>
    </>
  );
}
