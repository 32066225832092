import axios from "../api/axios.js";
import { Button, Card, Input, Typography } from "@material-tailwind/react";
import { GENERATE_OTP } from "../Constants.js";
import { useEffect } from "react";
import { useAuth } from "../store/auth.js";
import { useNavigate } from "react-router";

export default function GenerateOTP() {

  const setUserId = useAuth((state) => state.setUserId);
  const setToken = useAuth((state) => state.setToken);
  const resetAuth = useAuth((state) => state.resetAuth);
  const userId = useAuth((state) => state.userId)
  const navigate = useNavigate();

  const data_auth = {
    "number": userId
  }
  const handleSubmit = async (e) => {
    try {
      const response = await axios.post(
        GENERATE_OTP,
        data_auth,
        {
          method: "post",
          headers: { "Content-Type": "application/json" },
        })
      if (response.status === 200) {
        alert("proceed to login")
        setUserId(userId)
        navigate("/login")
        // console.log(response.data)
        // setMobile(response.data[number]);
        setToken(response.data["token"]);
      } else {
        alert("Failed to generate OTP [1]");
      }
    } catch (err) {
      if (err?.response?.data?.status_message) {
        alert(err?.response?.data?.status_message);
      } else if (err.response.status === 500) {
        alert("Failed to generate OTP [2]");
      } else if (err.response.status === 401) {
        alert(err?.response?.data?.status_message);
        // console.log(err.response)
      } else if (err.response.status === 403) {
        alert("user not logged in");
        resetAuth()
        navigate("/generateOTP")
      } else {
        alert("Failed to generate OTP [3]");
      }
    }
  };

  useEffect(() => {
    resetAuth()
    setUserId("")
  }, [])

  return (
    <>
      <div className="grid h-screen w-full justify-center content-center">
        <Card color="transparent" shadow={false} className=" w-min h-min mb-40">
          <Typography variant="h4" color="blue-gray" className="text-center">
            Generate OTP to login.
          </Typography>
          <Typography color="gray" className="mt-1 font-normal text-center ">
            Enter your number to generate OTP
          </Typography>
          <form className="mt-8 mb-2 w-80 max-w-screen-lg sm:w-96">
            <div className="mb-4 flex flex-col gap-6">
              <Input
                size="lg"
                label="Enter Number"
                name="mobile-number"
                color="blue"
                required
                autoComplete="off"
                onChange={(e) => setUserId(e.target.value.replace(/\D/g, "").substring(0, 10))}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault()
                    handleSubmit();
                  }
                }}
                value={userId}
              />
            </div>
            <Button
              className="mt-6"
              fullWidth
              color="blue"
              onClick={handleSubmit}
            >
              Get OTP
            </Button>
          </form>
        </Card>
      </div>
    </>
  );
}



