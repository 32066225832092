import Topnav from "./Topnav";
import GenerateOTP from "./GenerateOTP";
import Mapping from "./Mapping";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import MachineInfo from "./MachineInfo";
import Login from "./Login";
import PrivateRoute from "./PrivateRoute";
import PageNotFound from "./PageNotFound";
import Analytics from "./Analytics";

export default function Layout() {
  return (
    <>
      <Router>
        <Topnav />
        <Routes>
          <Route element={<PrivateRoute />}>
            <Route exact path="/mapping" element={<Mapping />} />
            <Route exact path="/machineInfo" element={<MachineInfo />} />
            <Route exact path="/analytics" element={<Analytics />} />
          </Route>
          <Route exact path="/" element={<GenerateOTP />} />
          <Route exact path="/generateOTP" element={<GenerateOTP />} />
          <Route exact path="/login" element={<Login />} />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </Router>
    </>
  );
}