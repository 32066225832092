import axios from "../api/axios.js";
import { Card, Button, Typography, Input } from "@material-tailwind/react";
import { useEffect } from "react";
import { useAuth } from "../store/auth.js"
import { useNavigate } from "react-router";
import { LOGIN_OTP } from "../Constants.js";

export default function Login() {

  // const setUserId = useAuth((state) => state.setUserId);
  const setLoggedIn = useAuth((state) => state.setLoggedIn);
  const otp = useAuth((state) => state.otp);
  const setOTP = useAuth((state) => state.setOTP);
  const userId = useAuth((state) => state.userId)

  const setToken = useAuth((state) => state.setToken);
  const resetAuth = useAuth((state) => state.resetAuth);

  const navigate = useNavigate();
  const data_auth = {
    "number": userId,
    "otp": otp,
  }

  const handleSubmit = async (e) => {
    try {
      const response = await axios.post(
        LOGIN_OTP,
        data_auth,
        {
          method: "post",
          headers: {
            "Content-Type": "application/json",
            // "x-access-tokens": token,

          },
          //  auth: {
          //   otp:otp
          // },
        })
      if (response.status === 200) {
        alert("OTP verified successfully.")
        setLoggedIn(true);
        setToken(response.data["jwt"]);
        navigate("/mapping")
        // console.log(response.data)
      } else {
        alert("Failed to generate OTP [1]");
      }
    } catch (err) {
      if (err?.response?.data?.status_message) {
        alert(err?.response?.data?.status_message);
      } else if (err.response.status === 500) {
        // console.log(err.response.data)
        alert("Failed to generate OTP [2]");
      } else if (err.response.status === 401) {
        alert(err?.response?.data?.status_message);
      } else if (err.response.status === 403) {
        alert("user not logged in");
        resetAuth()
        navigate("/generateOTP")
      } else {
        alert("Failed to generate OTP [3]");
      }
    }
  };
  useEffect(() => {
    // console.log(token)
  })

  return (
    <>
      <div className="grid h-screen w-full justify-center content-center">
        <Card color="transparent" shadow={false} className=" w-min h-min mb-40">
          <Typography variant="h4" color="blue-gray" className="text-center">
            Login
          </Typography>
          <Typography color="gray" className="mt-1 font-normal text-center ">
            Enter OTP to login.
          </Typography>
          <form className="mt-8 mb-2 w-80 max-w-screen-lg sm:w-96">
            <div className="mb-4 flex flex-col gap-6">
              <Input
                size="lg"
                label="Enter OTP"
                color="blue"
                autoComplete="off"
                onChange={(e) => setOTP(e.target.value.replace(/\D/g, "").substring(0, 4))}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault()
                    handleSubmit();
                  }
                }}
                value={otp}
                required
              />
            </div>

            <Button
              className="mt-6"
              fullWidth
              color="blue"
              onClick={handleSubmit}
            >
              Login
            </Button>
          </form>
        </Card>
      </div>
    </>
  )
}




