import { Input, Button, Typography } from "@material-tailwind/react"
import axios from "../api/axios";
import { useAuth } from "../store/auth";
import { useState, useEffect } from "react";
import { MAPPING_ORDERS_ANALYTICS } from "../Constants";

export default function OrderAnalytics() {

    const token = useAuth((state) => state.token);
    const currentDate = new Date().toISOString().split("T")[0];
    const [dateData, setDateData] = useState({
        from: currentDate,
        to: currentDate,
    });
    const [analyticsOrderData, setAnalyticsOrderData] = useState({});

    const handleChange = (event) => {
        let name = event.target.name;
        const value = event.target.value;
        setDateData((values) => ({ ...values, [name]: value }));
    };

    const handleOrderAnalytics = async () => {
        try {
            const response = await axios.get(MAPPING_ORDERS_ANALYTICS, {
                headers: {
                    "Content-Type": "application/json",
                    "x-access-tokens": token,
                },
                params: { from: dateData.from, to: dateData.to },
            });
            if (response.status === 200) {
                setAnalyticsOrderData(response.data["data"]);
            } else {
                alert("No server response [1]");
            }
            console.log("orders:", response);
        } catch (err) {
            if (err?.response?.data?.status_message) {
                alert(err?.response?.data?.status_message);
            } else if (err.response.status === 400) {
                alert(err.response.data.status_message)
            } else if (err.response.status === 500) {
                alert(err.response.data.status_message)
            } else {
                alert(err.response.data.status_message)
            }
        }
    }

    useEffect(() => {
    }, [analyticsOrderData])

    return (
        <>
            <div>
                <div className="flex flex-col gap-2 w-60 text-center mx-auto justify-center pt-4">
                    <Input
                        label="From"
                        type="date"
                        pattern="\d{4}-\d{2}-\d{2}"
                        name="from"
                        color="blue"
                        value={dateData.from}
                        onChange={handleChange}
                    />
                    <Input
                        label="To"
                        type="date"
                        pattern="\d{4}-\d{2}-\d{2}"
                        name="to"
                        color="blue"
                        value={dateData.to}
                        onChange={handleChange}
                    />
                    <div className="flex flex-row mx-auto justify-center">
                        <Button className="w-16" color="blue" onClick={handleOrderAnalytics}>
                            <span className="text-center">Go</span>
                        </Button>
                    </div>
                </div>
                <Typography className="font-bold text-black text-xl text-center pt-3">Total Order Assigned</Typography>
                <table className="border-collapse border border-slate-500 mt-3 w-full">
                    <thead>
                        <tr className="border-b border-blue-gray-100 bg-blue-gray-50 p-4">
                            <th className="text-center p-4 font-normal leading-none opacity-70">
                                Total Order Count
                            </th>
                            <th className="text-center p-4 font-normal leading-none opacity-70">
                                Splitted Order Count
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className="text-center p-2">
                                {(analyticsOrderData.total_order_count) ? (analyticsOrderData.total_order_count) : ("0")}
                            </td>
                            <td className="text-center p-2">
                                {(analyticsOrderData.total_splitted_order_count) ? (analyticsOrderData.total_splitted_order_count) : ("0")}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </>
    )
}