import {
  Button,
  // Dialog,
  // DialogBody,
  // DialogFooter,
  // DialogHeader,
  Input,
  Option,
  Select,
  Switch,
  IconButton,
  // Tooltip
} from "@material-tailwind/react";
import { faSearch, faCalendarDays, faC, faM, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { GET_MACHINE_URL, SET_MAPPING_URL, MAPPING_SEARCH } from "../Constants";
import axios from "../api/axios";
import { useAuth } from "../store/auth";
import { useStore } from "../store/Zustand";
import MappingData from "./MappingData";
import Notification from "./Notification";
import { useNavigate } from "react-router";

export default function Mapping() {
  // { getMapping, test, setTest }
  const token = useAuth((state) => state.token);
  const resetAuth = useAuth((state) => state.resetAuth);
  // drop-down machine value
  const [machine_list, set_machine_list] = useState([]);
  const [machine, setMachine] = useState("");

  const navigate = useNavigate();

  const [qs, setQs] = useState("");

  // const { test, setTest, getMapping } = props;
  // console.log(props)

  const setSearch = useStore((state) => state.setSearch);
  const searchState = useStore((state) => state.searchState);

  // const searchTrigger = useStore((state) => state.searchTrigger);
  const setTrigger = useStore((state) => state.setTrigger);

  // mapping variables
  const [mapping, setMapping] = useState({ operator_id: "", carefill_id: "" });
  const mapping_list = useStore((state) => state.mapping_list);
  const setMappingList = useStore((state) => state.setMappingList);

  const filter = useStore((state) => state.filter);
  const setFilter = useStore((state) => state.setFilter);
  const is_linked = useStore((state) => state.is_linked);

  // const test_list = useStore((state) => state.test_list);
  const setTestList = useStore((state) => state.setTestList);

  // machine status message.
  const [machine_status, set_machine_status] = useState({
    status: false,
    message: 1,
  });

  // backend response message
  const [notify, setNotify] = useState({ status: 0, message: "" });

  // collecting input fields data
  const set_mapping = (e) => {
    setMapping((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  // Get machine list
  const get_machine_list = async () => {
    // console.log(token)
    await axios
      .get(GET_MACHINE_URL, {
        headers: {
          "Content-Type": "application/json",
          "x-access-tokens": token,
        },
      },)
      .then((response) => {
        set_machine_list(response.data.data);
      })
      .catch((error) => {
        set_machine_list(error.response.data.data);
        if (error.status === 403) {
          alert(error.response.data.status_message)
          resetAuth()
          navigate("/generateOTP")
        } else {
          alert(error.response.data.status_message)
        }
      });
  };

  // post request to create mapping
  const create_mapping = async () => {
    let is_machine = false;

    for (const iterator of machine_list) {

      if (iterator.machine_number === parseInt(machine)) {

        if (iterator.machine_status === 2) {
          // IN-USE
          is_machine = true;
          alert("Machine is in use")
          set_machine_status((prev) => ({ ...prev, message: 1 }));
        } else if (iterator.machine_status === 4) {
          // NEED - MAINTENANCE
          is_machine = true;
          set_machine_status((prev) => ({ ...prev, message: 2 }));
        } else if (
          iterator.machine_status === 0 ||
          iterator.machine_status === 3 ||
          iterator.machine_status === 5
        ) {
          alert("machine is not available")
          is_machine = true;
          set_machine_status((prev) => ({ ...prev, message: 3 }));
        }
      }
    }

    const form = {
      operator_id: mapping.operator_id,
      carefill_id: mapping.carefill_id,
      machine_number: machine,
    };
    if (form.carefill_id === "" || form.machine_number === "") {
      alert("missing mandatory fields.")
    } else {

      if (!is_machine) {
        await axios
          .post(SET_MAPPING_URL, form, {
            headers: {
              "Content-Type": "application/json",
              "x-access-tokens": token,
            },
          })
          .then((response) => {
            // console.log(response)
            if (response.status === 201) {
              setTrigger(false)
              setMappingList(response.data.data);
              setNotify({ status: 1, message: response.data.status_message });
            } else {
              setNotify({ status: 4, message: "Unknown Error" });
            }
          })
          .catch((error) => {
            if (error.response.status === 409) {
              alert(error?.response?.data?.status_message)
            } else if (error.response.status === 403) {
              alert(error.response.data.status_message)
              resetAuth()
              navigate("/generateOTP")
            } else if (error.response.status === 400) {
              alert(error.response.data.status_message)
            } else {
              setNotify({ status: 3, message: "Response not found" });
              // alert("Server is Down " + error);
            }
          });
        setTimeout(() => {
          setNotify({ status: 0, message: "" });
        }, 1200);
        setMapping({ operator_id: "", carefill_id: "" }); // Reset form state
      } else {
        set_machine_status((prev) => ({ ...prev, status: true }));
      }
    }
  };


  const searchMappings = async () => {
    try {
      // console.log(token)
      // console.log(qs);
      if (token !== "" && qs !== "") {
        const response = await axios.get(`${MAPPING_SEARCH}/${searchState}/${qs}`, {
          headers: {
            "x-access-tokens": token,
          },
        });
        console.log(response);
        if (response.status === 200) {
          setTestList(response.data.data);
          // setMappingList(response.data.data);
          // console.log(response.data.data);
          // setQs("")
          setTrigger(true);
        } else {
          alert("Failed to fetch mappings [1]");
        }
      } else if (token !== "" && qs === "") {
        alert("please provide a valid value")
        // get_machine_list(mapping_list)
      }
    } catch (err) {
      if (err?.response?.data?.status_message) {
        alert(err?.response?.data?.status_message);
      } else if (err.response.status === 401) {
        alert("Session Expired");
        resetAuth();
      } else if (err.response.status === 403) {
        alert(err.response.data.status_message)
        resetAuth()
        navigate("/generateOTP")
      } else {
        alert(err.response.data.status_message);
        // console.log(err.response)
      }
    }
  };
  function refreshPage() {
    window.location.reload(false);
  }

  useEffect(() => {
    // console.log(mapping_list_1)
    get_machine_list();
  }, [mapping_list, filter, machine_status, is_linked, token]);

  {/* <div className="w-64">
            <Input
              label="Operator Id"
              type="number"
              name="operator_id"
              value={mapping.operator_id}
              onChange={set_mapping}
            ></Input>
          </div> */}
  return (
    <>
      <div className="grid min-h-screen min-w-full justify-center content-start border-solid mt-5 overflow-hidden">
        <h1 className="text-center text-3xl font-bold">
          Order Machine Mapping
        </h1>
        <div className="flex justify-end duration-1000">
          {notify && <Notification message={notify} />}
        </div>
        <div className="grid grid-cols-2 p-2 mt-1">
          <div className="grid grid-cols-4 gap-[70px] m-2 ml-9">
            <div className="lg:w-64 md:w-auto">
              <Input
                label="Carefill Id"
                type="number"
                color="blue"
                name="carefill_id"
                value={mapping.carefill_id}
                // value={mapping.carefill_id === "" ? (refreshPage) : (<></>)}
                onChange={set_mapping}
              >
              </Input>
            </div>
            <div className="lg:w-[250px] md:w-[200px] ml-6">
              <Select
                className="lg:w-[250px] md:w-[200px]"
                label="Machine Name"
                color="blue"
                onChange={(e) => {
                  setMachine(e);
                }}
                placeholder="Select a Machine Name"
              >
                {machine_list.map((machine, index) =>
                  machine.machine_status === 1 ? (
                    <Option key={index} value={`${machine.machine_number}`}>
                      {`${index + 1} - ${machine.machine_name}`}
                    </Option>
                  ) : machine.machine_status === 2 ? (
                    <Option key={index} value={`${machine.machine_number}`}>{machine.machine_name} is mapped</Option>
                  ) : (
                    <Option key={index} value={`${machine.machine_number}`}>{machine.machine_name} not available</Option>
                  )
                )}
              </Select>
            </div>
            <div className="grid grid-cols-2 ml-8">
              <div className="ml-4">
                <Button
                  variant="gradient"
                  size="md"
                  color="blue"
                  className="hidden lg:inline-block md:inline-block sm:inline-block xs:inline-block"
                  onClick={create_mapping}
                >
                  Go
                </Button>
              </div>
              <div className="ml-14 mt-3">
                {/* <Tooltip content="Active Mappings"> */}
                <Switch
                  color="blue"
                  onChange={() => {
                    filter === true ? setFilter(false) : setFilter(true);
                  }}
                  checked={filter}
                />
                {/* </Tooltip> */}
              </div>
            </div>
          </div>
          <div className="grid grid-cols-2 m-2 lg:ml-[270px] md:ml-[150px]">
            <div className="grid grid-cols-2 gap-[135px]">
              <div className="lg:w-[300px] md:w-60">
                {searchState === 1 || searchState === 2 ? (
                  <Input
                    label="Machine Number or Carefill ID"
                    icon={qs ? (<button onClick={refreshPage}
                    ><FontAwesomeIcon icon={faXmark} /></button>) : (<FontAwesomeIcon icon={faSearch} />)}
                    onChange={(e) => setQs(e.target.value)}
                    value={qs}
                    color="blue"
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        searchMappings();
                      }
                    }}
                  />
                ) : (
                  <Input
                    size="lg"
                    label="Search By Date"
                    icon={qs ? (<button onClick={refreshPage}><FontAwesomeIcon icon={faXmark} color="blue-gray" /></button>) : (<FontAwesomeIcon icon={faSearch} color="blue-gray" />)}
                    type="date"
                    onChange={(e) => setQs(e.target.value)}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        searchMappings();
                      }
                    }}
                    color="blue"
                    value={qs}
                    // pattern="\d{4}-\d{2}-\d{2}"
                    name="search_date"
                  />
                )}
              </div>
              <div className="grid grid-cols-3 ml-10">
                <div className="lg:ml-[102px] md:ml-[54px]">
                  <IconButton
                    color={searchState !== 0 ? "gray" : "blue"}
                    onClick={() => {
                      setSearch(0);
                    }}
                    ripple={false}
                  >
                    <FontAwesomeIcon icon={faCalendarDays} size="2x" />
                  </IconButton>
                </div>
                <div className="lg:ml-[122px] md:ml-[82px]">
                  <IconButton
                    color={searchState !== 1 ? "gray" : "blue"}
                    onClick={() => {
                      setSearch(1);
                    }}
                    ripple={false}
                  >
                    <FontAwesomeIcon icon={faC} size="2x" />
                  </IconButton>
                </div>
                <div className="lg:ml-[140px] md:ml-[110px]">
                  <IconButton
                    color={searchState !== 2 ? "gray" : "blue"}
                    onClick={() => {
                      setSearch(2);
                    }}
                    ripple={false}
                  >
                    <FontAwesomeIcon icon={faM} size="2x" />
                  </IconButton>
                </div>
              </div>
            </div>
            <div className="lg:ml-[240px] md:ml-[192px]">
              <Button
                variant="gradient"
                size="md"
                color="blue"
                className="hidden lg:inline-block md:inline-block sm:inline-block xs:inline-block"
                onClick={searchMappings}
              >
                Go
              </Button>
            </div>
          </div>
        </div>
        <div className="w-full max-h-min">
          <MappingData />
        </div>
      </div >
    </>
  );
}
