import axios from "axios";

export default axios.create({
  baseURL: process.env.REACT_APP_API_PATH,
  timeout: 30000,
  headers: {
    "ngrok-skip-browser-warning": "69420",
    "Content-Type": "application/json",
  },
});
