import { faChartPie, faGear, faMapPin } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Navbar, Typography } from "@material-tailwind/react";
import { Link } from "react-router-dom";
import { useAuth } from "../store/auth";
import logo from "./logo.png";
export default function Topnav() {
  const token = useAuth((state) => state.token);
  const userId = useAuth((state) => state.userId);
  const resetAuth = useAuth((state) => state.resetAuth);

  // function logoutUser() {
  //   if (otp !== "") resetAuth();
  // }

  function logoutUser() {
    // console.log("otp:", otp)
    if (userId !== "") {
      resetAuth();
    } else {
      alert("No OTP found. User might not be logged in.");
    }
  }


  return (
    <>
      <Navbar className="mx-auto max-w-screen-xl py-2 px-4 lg:px-8 lg:py-4">
        <div className="container mx-auto flex items-center justify-between text-blue-gray-900">
          <Typography
            as="a"
            href="/mapping"
            className="mr-4 cursor-pointer py-1.5 font-medium"
          >
            <img
              className="hidden h-10 w-auto lg:block md:block sm:block"
              src={logo}
              alt="logo_image"
            />
          </Typography>
          {token && userId ? (
            <div className="hidden lg:block md:block sm:block ml-[50%]">
              <Link
                to="/mapping"
                className="m-2 hover:hover:bg-gray-300 rounded-md p-3"
              >
                <FontAwesomeIcon icon={faMapPin} className="mr-1" />
                Mapping
              </Link>
              <Link
                to="/machineInfo"
                className="m-2 hover:hover:bg-gray-300 rounded-md p-3"
              >
                <FontAwesomeIcon icon={faGear} className="mr-1" />
                Machine-Info
              </Link>
              <Link
                to="/analytics"
                className="m-2 hover:hover:bg-gray-300 rounded-md p-3"
              >
                <FontAwesomeIcon icon={faChartPie} className="mr-1" />
                Analytics
              </Link>
            </div>
          ) : (
            <span>Welcome!</span>
          )}
          <Button
            variant="gradient"
            size="sm"
            color="blue"
            onClick={logoutUser}
            className="hidden lg:inline-block md:inline-block sm:inline-block bg-light-blue-400"
          >
            {/* Logout */}
            <span>{token && userId ? "Logout" : "Login"}</span>
          </Button>
        </div>
      </Navbar>
    </>
  );
}
