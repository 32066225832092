import { Typography, Tabs, TabsHeader, TabsBody, Tab, TabPanel } from "@material-tailwind/react";
import OrderAnalytics from "./OrderAnalytics";
import MachineAnalyticsDetails from "./MachineAnalyticsDetails";

export default function Analytics() {

    const currentDate = new Date();

    const tabs = [
        {
            label: "Total Order Assigned",
            value: "total order assigned",
        },
        {
            label: "Per Machine Assigned Orders",
            value: "per machine assigned orders",
        },
    ];

    return (
        <>
            <div>
                <Typography className="w-full text-center text-2xl font-bold pt-5">Analytics</Typography>
                <hr className="pt-2" />
                <h3 className="w-full text-center">{currentDate.toLocaleString()}</h3>
                <Tabs value="total order assigned" className="m-7">
                    <div className="bg-gradient-to-r from-blue-300 to-light-blue-300">
                        <TabsHeader
                            className="bg-transparent"
                            indicatorProps={{
                                className: "bg-gray-900/10 shadow-none !text-gray-900",
                            }}
                        >
                            {tabs.map(({ label, value }) => (
                                <Tab key={value} value={value} className="lg:text-xl md:text-lg">
                                    {label}
                                </Tab>
                            ))}
                        </TabsHeader>
                    </div>
                    <TabsBody>
                        {tabs.map(({ value }) => (
                            <TabPanel key={value} value={value}>
                                {value === "total order assigned" && <OrderAnalytics />}
                                {value === "per machine assigned orders" && <MachineAnalyticsDetails />}
                            </TabPanel>
                        ))}
                    </TabsBody>
                </Tabs>
            </div>
        </>
    )
}